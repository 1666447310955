<template>
<main class="container bg text-center">
  <div class="text my-3">SURVIVE</div>
  <div class="row langs mx-auto align-items-center justify-content-center">
    <div class="col-xl-3 col-md-4 col-6 pt-4 mx-auto"  v-for="(entry, i) in languages"
         :key="`Lang${i}`" :value="entry">
      <b-img :src="`${entry.flag}`" width="200"  height="100" @click="setLanguage(entry.language, entry.title, entry.flag)"></b-img>
    </div>
  </div>
  <div class="ratio ratio-21x9 mx-auto">
    <iframe :src="'https://www.youtube.com/embed/'+video+'?enablejsapi=1&rel=0&amp&autoplay=1&mute=1'"  allow='autoplay' title="YouTube video" v-if="video" allowfullscreen></iframe>
  </div>

</main>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "Main",
  data() {
    return {
      video:'',
      languages: [
        {
          flag: require("@/assets/flags/flag_en.svg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/flags/flag_fr.svg"),
          language: "fr",
          title: "French",
        },

      ],
      text: null,
      flag: null,
      value: null,
      lan: i18n.locale,
    }
  },
  beforeCreate: function() {
    document.body.className = 'main';
  },
  created(){
    this.getInfos('fr')
  },

  methods: {
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      i18n.locale = locale;
      localStorage.setItem('lang',locale)
      this.$router.push({name:'Home'})
    },
    getInfos(lang){
      this.$http
          .get('infos/'+lang)
          .then(response => {
            response.data.forEach( (element) => {

              if(element.type == 'video') this.video = element.content

            })
            this.$forceUpdate();
          })
          .catch(error => {
            console.log(error)
          })
    }


  }
}
</script>

<style scoped>


img:hover{
  transform: scale(1.06);
  cursor: pointer;

}
.text {
padding-top: 5vh;
  color: #cf1b1b;
  font-size: 60px;
  letter-spacing: 8px;
  cursor: pointer;
  font-family: "Monoton";
}
@media only screen and (max-width: 600px) {
  img{
    width: 100px!important;
    height: 50px;
  }
  .langs{
    width: 100%;
  }
}
.ratio{
  margin-top: 40px;
  margin-bottom: 40px;
  width: 80%;
}
.langs{
  width: 60%;
}
@media screen and (max-width: 1100px) {
  .ratio {
    width: 100% !important;
  }
}

</style>